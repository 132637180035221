export const LOGIN = '/'
export const PASS_RECOVERY = '/recovery'
export const BOOKINGS = '/bookings'
export const CARS_FOR_WASH = '/cars_for_wash'
export const BOOKING_TIMES = '/booking_times'
export const CONTACT_DETAILS = '/contact_details'
export const ABOUT_DETAILS = '/about_details'
export const NEWS_DETAILS = '/news_details'
export const SERVICES_DETAILS = '/services_details'
export const PACKAGES = '/packages'
export const SERVICES = '/services'
export const ACTIVE_BOOKINGS = '/active_bookings'
export const PROCESS_BOOKINGS = '/process_bookings'
export const DONE_BOOKINGS = '/done_bookings'
export const CANCELED_BOOKINGS = '/canceled_bookings'
export const SLIDER_PHOTOS = '/slider_photos'
